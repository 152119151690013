import React, { useState, useEffect } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, UncontrolledCollapse, Container, Col, Row } from 'reactstrap';
import unzFinalFaq from '../../assets/images/unz-final-faq.gif';
import futurePlans from '../../assets/images/future-plans.png';
import futurePlansMb from '../../assets/images/future-plans-mb.png';

const FuturePlans = () => {

    useEffect(() => {
        document.body.classList.add('futurePlans-page')
        return () => {
          document.body.classList.remove('futurePlans-page')
        }
    }, []);

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
        setOpen();
        } else {
        setOpen(id);
        }
    }


    const items = [
        {
           title: "What are the Random Ravers?",
           content: <p> A worldwide  music-driven  community united by  5,000  generative  Random Ravers, where each  character  is unique  as you are. Becoming a Random Raver means you are part of a community and The Raverse, it means you are here to UNTZ for eternity by building, learning and exploring the Metaverse together. </p>
        }, 
        {
            title: "What’s the utility?",
            content: <ul>
                <li> Join a crowd of party lovers with big dreams. </li>
                <li> Participate in brand experiences such as exclusive drops, IRL & Metaverse events, digital experiences, wearables and more. This is just the beginning. </li>
                <li> Receive exclusive access to future drops. </li>
            </ul>
        },
        {
            title: "What is The Raverse?",
            content: <p> The Raverse is a place where labels are not necessary. At a Rave, there is no judgment. What you do or how you dress is your business. It is a place where you can find acceptance, surrounding yourself with positive energy and good vibes. <br/>We are building a Metaverse club where every holder can participate in different activities, events and shows. </p>
        }, 
        {
            title: "What is the UNTZ Machine?",
            content: <p> The UNTZ machine is the key for future drops. To get one you have to be a Raver holder and your tribe will determine what machine you get. The rarer your raver is, the rarer your untz machine will be. </p>
        }, 
        {
            title: "Will I own the commercial rights of my Raver?",
            content: <p> The UNTZ machine is the key for future drops. To get one you have to be a Raver holder and your tribe will determine what machine you get. The rarer your raver is, the rarer your untz machine will be. </p>
        }, 
        {
            title: "What is the UNTZ Machine?",
            content: <><p> A Random Raver is your new identity in the Metaverse. Use it, have fun with it and spread the UNTZ. We are here to party for eternity. Let’s build a brand together, the journey starts with your Raver and will continue with cool shit.</p>
            <p>Every holder owns the art IP. You may use the image as you wish so long as you own that particular Raver. Use of the image for profit must cease upon sale. Although the holder owns the IP do not own any logos or other art used and therefore cannot use it without written approval.</p></>
        },
        {
            title: "Future plans",
            content: <p> We are building The Raverse at Decentraland, we own a 3x3 estate and will build an awesome stage there where the community can have a voice in the Metaverse. Participate in Metaverse & IRL parties, get one-of-a-kind wearables and more. </p>
        }, 
    ]

    return (
        <>
            <div className="untz-faq-mb d-lg-none w-100"><img src={futurePlansMb} className="w-100 mx-auto d-lg-none"/></div>
            <Container fluid>
                <div className="untz-faq text-center d-none d-lg-block">
                    <img src={futurePlans} className="img-fluid mx-auto d-none d-lg-block"/>
                </div>
                <Row>
                    <Col sm={{size: 10, offset: 1 }}>
                        <Accordion open={open} toggle={toggle}>
                        { items.map((item, index) => {
                            return (
                                <AccordionItem>
                                    <AccordionHeader className="kinga" targetId={`future-plans-item-${index}`}><div className="item-num"><span>{index+1}</span></div> {item.title}</AccordionHeader>
                                    <AccordionBody accordionId={`future-plans-item-${index}`}>
                                        {item.content}
                                    </AccordionBody>
                                </AccordionItem>
                            )
                        })}
                        </Accordion>
                    </Col>
                </Row>
                <div className="text-center"><img src={unzFinalFaq} className="img-fluid unz-final-faq mx-auto"/></div>
            </Container>
        </>
    )
}

export default FuturePlans