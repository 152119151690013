import {React, useEffect} from "react";
import {Container, Col, Row} from 'reactstrap';
import ravers from '../../assets/images/RAVERS.png';
import raversMb from '../../assets/images/RAVERS-MB.png';
import raversLogo from '../../assets/images/RandomRaversLogo.png';
import asYouAre from '../../assets/images/as-you-are.gif';


const RandomRavers = () => {
    useEffect(() => {
        document.body.classList.add('randomRavers-page')
        return () => {
          document.body.classList.remove('randomRavers-page')
        }
    }, [])

    return (
        <>
            <Container fluid className="vh-100-main d-flex flex-column h-100">
                <Row className="my-auto row align-items-center">
                    <Col md={{size:5, offset:1}} className="text-center mb-5 mb-lg-0">
                        <img src={raversLogo} alt="Random Ravers" className="mx-auto img-fluid logo-random-ravers" />
                    </Col>
                    <Col md="6">
                        <div className="text-random-ravers mx-auto">
                            <p className="mb-0">We are a <span className="fw-semibold">worldwide music-driven community</span> united by 5,000 generative <span className="text-primary fw-semibold">RANDOM RAVERS</span>, where each character is unique</p>
                            <div className="img-w-bg"><img src={asYouAre} alt="As You Are" className="img-fluid"/></div>
                        </div>
                    </Col>
                </Row>
                <div className="text-center mt-auto">
                    <img className="mx-auto d-none d-md-block img-fluid" src={ravers}/>
                    <img className="mx-auto d-md-none img-fluid" src={raversMb}/>
                </div>
            </Container>
        </>
    )
}

export default RandomRavers