import React from 'react';
import {
    Routes,
    Route,
    HashRouter,
    BrowserRouter
} from "react-router-dom";

import '../assets/css/App.css';

import Header from "./components/common/Header"
import Footer from "./components/common/Footer"
import Home from "./pages/Home"
import RandomRavers from './pages/RandomRavers';
import UntzKing from './pages/UntzKing';
import FuturePlans from './pages/FuturePlans';
import { useState } from 'react';
function App() {
    const [section, setSection] = useState("home")
    return (
        <BrowserRouter>
            <Header setSection={setSection} section={section} />
            <main>
                 {section === "home" &&  <Home />}
                 {section === "randomravers" &&  <RandomRavers />} 
                 {section === "untz" && <UntzKing />}
                 {section === "faq" && <FuturePlans />}
               
            </main>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
