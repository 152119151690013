import {React, useEffect } from "react";
import {Container, Col, Row} from 'reactstrap';
import untzKingLogo from '../../assets/images/UNTZ-KING-LOGO.gif';
import follow from '../../assets/images/FOLLOW-THE-KING.png';


const UntzKing = () => {
    useEffect(() => {
        document.body.classList.add('untzKing-page')
        return () => {
          document.body.classList.remove('untzKing-page')
        }
    }, [])

    return (
        <>
            <Container fluid className="vh-100-main d-flex flex-column h-100">
                <Row className="my-auto row align-items-center">
                    <Col lg={{size:6}} className="text-lg-start text-center mb-5 mb-lg-0">
                        <div className="text-king mx-auto">
                            <img src={untzKingLogo} alt="Random Ravers" className="mx-auto img-fluid logo-untz-king" />
                            <div className="ps-lg-3">
                                <h1 className="kinga lh-08 text-uppercase mb-lg-4 mb-3">Hello Cheap Bastards,</h1>
                                <h1 className="kinga lh-08 text-uppercase mb-4 mb-lg-5">I am <span className="text-primary the-untz-king">the UNTZ King,</span> creator of the <span className="underline-random-ravers">Random Ravers</span> and <span className="underline-untz-movement">the UNTZ movement</span>.</h1>
                                <h1 className="kinga lh-08 text-uppercase">STAY CLOSE FOR<span className="text-more-cool"><br className="d-lg-none"/> MORE <span className="text-primary pe-3">COOL SH*T </span></span><br/>AND<span className="text-hard-beats pe-3"><span className="text-primary"> HARD BEATS</span>.</span></h1>
                            </div>
                        </div>
                    </Col>
                    <Col lg="6" className="align-self-end">
                        <div className="ps-lg-5">
                            <div className="cont-follow-king d-flex align-items-end">
                                <a href="#" className="mx-auto"><img src={follow} className="img-fluid" alt="Follow The King"/></a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default UntzKing